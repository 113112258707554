import React from "react"

class TaxiMap extends React.Component {

    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="map-responsive">
                <iframe src="https://www.google.com/maps/d/embed?mid=1jpi86bEbmc34JoTrC6k0E8SdSMWWShKr" width="450" height="600"></iframe>                
            </div>
        )
    }
}

export default TaxiMap