import React from "react"
import { graphql } from "gatsby"

import Mailto from "react-protected-mailto"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TaxiMap from "../components/taximap"
import SocialSharing from "../components/socialsharing"
import MailChimp from "../components/mailchimp"
import PopularPosts from "../components/popularposts"

class About extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Find a cross harbour taxi stand near you in Hong Kong" />
        <div className="col-lg-8 col-sm-12">
          <p>
            If you want to catch a taxi from one side of Hong Kong's harbour to the other, the easiest way is to find a cross harbour taxi stand.
          </p>
          <p>
            Here is a map to all the official cross harbour taxi stands in Hong Kong:
          </p>
          <TaxiMap />
          <hr/>
        </div>
        
        <div className="col-lg-3 col-sm-12">
            <SocialSharing location={this.props.location} title={siteTitle} siteUrl={data.site.siteMetadata.siteUrl}/>
            <PopularPosts />
            <MailChimp />
        </div>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`